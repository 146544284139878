@font-face {
    font-family: "gotham";
    src: url(./assets/Fonts/Gotham-Light.otf) format("opentype");
    font-weight: 100;
}
@font-face {
    font-family: "gotham";
    src: url(./assets/Fonts/Gotham-Book.otf) format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: "gotham";
    src: url(./assets/Fonts/Gotham-Medium.otf) format("opentype");
    font-weight: 600;
}
@font-face {
    font-family: "gotham";
    src: url(./assets/Fonts/Gotham-Bold.otf) format("opentype");
    font-weight: 700;
}
@font-face {
    font-family: "gotham";
    src: url(./assets/Fonts/Gotham-Black.otf) format("opentype");
    font-weight: 800;
}
@font-face {
    font-family: "gotham";
    src: url(./assets/Fonts/Gotham-Ultra.otf) format("opentype");
    font-weight: 900;
}
@media print {
    * {
        /* disable page clipping when printing */
        overflow: visible !important;
        /* disable background color removal */
        -webkit-print-color-adjust: exact !important;
        color-adjust: exact !important;
    }
    body {
        background-color: #ffffff !important;
    }
}
